@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: #B8B5B9;
}

.container {
  width: 100%;
  margin: 10px;
  background: #B8B5B985;

}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
  color: #FFFFFF;
  margin: 10px;
}

.logo {
  width: 200px;
  height: 200px;
  padding: 10px;
}

@media (min-width: 768px) {
  .container {
    width: 768px;
  }
  h1 {
    font-size: 58px;
    margin: 20px;
  }
}
