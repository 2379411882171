#countdown {
  width: 350px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  text-align: center;
  margin: auto;
}
#countdown .box {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #B96908A5;

  border-right: solid 2px rgba(255, 255, 255, 0.5);
}
#countdown .col-4:last-child .box {
  border-right-color: transparent;
}
#countdown .box p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: #FFFFFF;
}
#countdown .box .text {
  font-size: 12px;
  font-family: sans-serif;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
  #countdown {
    width: 500px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  #countdown .box p {
    font-size: 30px;
  }
  #countdown .box .text {
    font-size: 22px;
  }
}
