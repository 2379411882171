.optin {
  margin-top: 8%;
  margin-bottom: 8%;
}
.optin p {
  font-size: 14px;
  font-weight: 100;
}
.optin button {
  color: #fff;
  padding: 6px 22px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  background-color: #43B908;
  border: solid 2px transparent;
  cursor: pointer;
}
.optin button:hover {
  border-color: #319400;
}

#modal {
  position: fixed;
  top: 10%;
  left: 25%;
  width: 50%;
  background: #fff;
  transition: opacity 1s;
  /* box-shadow: 0 7px 2px #444; */
  z-index: 99999;
  opacity: 0;
}
#modal.is_open {
  opacity: 0.8;
}
#modal .wrapper {
  color: #444;
  text-align: center;
  padding: 30px;
}
