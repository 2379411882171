@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
html,
body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Oxygen", sans-serif;
}

#main {
  min-height: 100vh;
  overflow: hidden;
}

#main .video-container {
  position: relative;
}

#main .video-container .video {
  /* position: fixed; */
  min-height: 100vh;
  min-width: 100vw;
}

#main .video-container .video-overlay {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: #3c3c3c;
  opacity: 0.6;
}

#main .video-container .video-text {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

#main .video-container .video-text * {
  /* color: #ffffff; */
  /* padding-left: 15px;
  padding-right: 15px; */
  -ms-flex-line-pack: center;
      align-content: center;
}

#two {
  padding-left: 15px;
  padding-right: 15px;
}